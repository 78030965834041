@tailwind base;
@tailwind components;
@tailwind utilities;


.ant-pagination .ant-pagination-item {
    background: white;
    color: gray;
    border-color: gray;
}


.ant-pagination .ant-pagination-item-active a {
    background: gray;
    color: white;
    margin: 0;
    padding: 0;
    width: auto;
    border-radius: 4px;
}

.ant-table-wrapper td.ant-table-column-sort {
    background-color: #2D3748;
}


.ql-toolbar.ql-snow{
    background: white;
}